import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import ERC20 from "../dApp/erc20.js";
import Web3Utils from "../utils/web3.utils";
import { convertToK } from "../utils/number.utils";
import Web3Functions from "../functions/web3.functions";
import * as Web3 from "web3";

function useBalance() {
  const token_list = Object.keys(
    ERC20[process.env.REACT_APP_MAINNET]["assets"]
  );
  const [balances, setBalances] = useState(null);
  const { account } = useWeb3React();
  const blockNumber = usePrice();

  useEffect(() => {
    async function getAllBalances() {
      try {
        if (account) {
          let asset_balances = [];
          for await (let token of token_list) {
            const bal = convertToK(
              await Web3Functions.getBalance(token, account)
            );
            asset_balances.push({
              token,
              bal,
            });
          }

          setBalances(asset_balances);
        }
      } catch (err) {
        console.log(err);
      }
    }

    getAllBalances();
  }, [account, blockNumber]);

  return balances;
}

function usePrice() {
  const [blockNumber, setBlockNumber] = useState(0);

  useEffect(() => {
    const subscription = Web3Utils.subscribe("newBlockHeaders", (err, res) => {
      if (err) {
        console.log(`Watch error: ${err}`);
      } else {
        // set new incoming block number in the chain
        if (res.number > blockNumber) {
          setBlockNumber(res.number);
        }
      }
    });

    return () =>
      subscription.unsubscribe((err, success) => err && console.log(err));
  }, []);

  return blockNumber;
}

const useAccount = () => {
  const client = new Web3(window.ethereum);

  //let account = localStorage.getItem('account')
  let [account, setAccount] = useState(localStorage.getItem("account"));

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      // account = accounts[0]
      setAccount(accounts[0]);
    });
  }

  return account;
};

export { useBalance, usePrice, useAccount };
