import { InjectedConnector } from "@web3-react/injected-connector";
require("dotenv").config();

const { REACT_APP_NODE_ENV, REACT_APP_MAINNET } = process.env;
const ChainIds = {
  /* mainnet: 1,
    ropsten: 3,
    rinkeby: 4,
    goerli: 5,
    kovan: 42, */
  polygon: 137,
};

const supportedChainIds = REACT_APP_MAINNET.split(",").map(
  (id) => ChainIds[id]
);

export const connector = new InjectedConnector({
  supportedChainIds,
});
