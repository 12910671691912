import React, { lazy, Suspense, useCallback, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  // Switch,
  useLocation,
} from "react-router-dom";
//import Account from "./Account";

import CreateEvent from "./CreateEvent";
import Home from "./Home";
//import Leaderboards from "./Leaderboards";
import Trade from "./Trade";
import Trading from "./Trading";
//import EventParticipation from "./EventParticipation";
import Portfolio from "./Portfolio";
import Orders from "./Orders";
//import CreateEventNew from './CreateEventNew';

import Navbar from "./Navbar";
import Events from "./Events";
import loadable from "@loadable/component";
import TrackAnalytics from "./Firebase/TrackAnalytics";

//import Withdraw from "./Withdraw"

const CreateEventNew = loadable(() => import("./CreateEventNew"));
const Account = loadable(() => import("./Account"));
const Withdraw = loadable(() => import("./Withdraw"));
const Leaderboards = loadable(() => import("./Leaderboards"));
const EventParticipation = loadable(() => import("./EventParticipation"));

export default function Router(
  {
    /* activeHome */
  }
) {
  return (
    <BrowserRouter>
      <Navbar />
      <TrackAnalytics>
        <Routes>
          <Route path="/" element={<Events />} />
          <Route path="/create" element={<CreateEventNew />} />
          {/* <Route path="/createnew" element={<CreateEventNew />} /> */}
          <Route
            path="/leaderboards/:event_id/:status"
            exact
            element={<Leaderboards />}
          />
          <Route path="/account" element={<Account />} />
          <Route path="/withdraw" element={<Withdraw />} />
          {/* <Route path="/trading" element={<Trading />} />
				<Route path="/portfolio" element={<Portfolio />} />
				<Route path="/trade" element={<Trade />} />
				<Route path="/orders" element={<Orders />} /> */}
          <Route
            path="/event/:event_id/:vestibule_id"
            exact
            element={<EventParticipation />}
          />
        </Routes>
      </TrackAnalytics>
    </BrowserRouter>
  );
}
