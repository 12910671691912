import React, { useRef, useState } from "react";
import {
  Fab,
  Stack,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Slider,
  useScrollTrigger,
  Zoom,
  Drawer,
  Container,
  IconButton,
  Button,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Close } from "@mui/icons-material";
import FilterAssets from "./FilterAssets";
import Web3Utils from "../../utils/web3.utils";
import ERC20 from "../../dApp/erc20.js";
import ResponsiveRangeInput from "./ResponsiveRangeInput";
import "./style.css";

export default function ResponsiveFilter({ onSubmit }) {
  const participantsRef = useRef();
  const joiningFeesRef = useRef();
  const cpaRef = useRef();
  const ipaRef = useRef();

  const [filters, setFilters] = useState({
    asset: [...Object.keys(ERC20[process.env.REACT_APP_MAINNET]["assets"])],
    participants: {
      min: 0,
      strict: false,
    },
    joining_fees_in_wei: {
      min: 0,
      strict: false,
    },
    current_pool_amount: {
      min: 0,
      strict: false,
    },
    initial_pool_in_wei: {
      min: 0,
      strict: false,
    },
  });

  /* const handleFiltersChanged = (new_filters) => {
        setFilters({
            ...filters,
            [Object.keys(new_filters)[0]]: new_filters[Object.keys(new_filters)[0]]
        })
    } */

  const onApplyFilters = () => {
    /* console.log(participantsRef.current.children[2].firstChild.value)
        console.log(joiningFeesRef.current.children[2].firstChild.value)
        console.log(ipaRef.current.children[2].firstChild.value)
        console.log(cpaRef.current.children[2].firstChild.value) */
  };

  const [showFilters, setShowFilters] = useState(false);

  const handleFiltersShow = () => {
    setShowFilters(!showFilters);
  };

  function ZoomOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Zoom appear={false} in={!trigger}>
        {children}
      </Zoom>
    );
  }

  const FiltersDrawer = () => (
    <Container
      maxWidth="xl"
      sx={{
        //background: (theme) => theme.palette.primary.drawer  + "80"
        background: "#000B",
        maxHeight: "80vh",
      }}
    >
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="button"
            sx={{
              my: 3,
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Filters
          </Typography>
          <Button variant="contained" onClick={onApplyFilters}>
            SUBMIT
          </Button>
          <IconButton onClick={handleFiltersShow}>
            <Close />
          </IconButton>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="button">Choose Assets</Typography>
          {/* <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{
                                    color: (theme) => theme.palette.primary.light,
                                    '&.Mui-checked': {
                                        color: (theme) => theme.palette.secondary.main,
                                    },
                                }}
                            />}
                            label="WMATIC"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                sx={{
                                    color: (theme) => theme.palette.primary.light,
                                    '&.Mui-checked': {
                                        color: (theme) => theme.palette.secondary.main,
                                    },
                                }}
                            />}
                            label="DAI"
                        />
                    </FormGroup> */}
          <FilterAssets />
        </Stack>
        <Stack
        //spacing={0}
        >
          <Typography variant="button">Participants</Typography>
          <Slider
            sx={{
              width: "98%",
            }}
            value={filters?.participants?.min}
            color="secondary"
            defaultValue={0}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value} participants`}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                participants: { min: newValue, strict: false },
              })
            }
          />
        </Stack>
        <Stack
        //spacing={1}
        >
          <Typography variant="button">Joining Fees</Typography>
          <Slider
            sx={{
              width: "98%",
            }}
            ref={joiningFeesRef}
            color="secondary"
            //value={Web3Utils.toEther(filters?.joining_fees_in_wei?.min.toString())}
            defaultValue={0}
            //onChange={(_, newValue) => handleFiltersChanged({ joining_fees_in_wei: { min: Web3Utils.toWei(newValue.toString()), strict: false } })}
          />
        </Stack>
        <Stack
        //spacing={1}
        >
          <Typography variant="button">Current Pooled Amount</Typography>
          <Slider
            sx={{
              width: "98%",
            }}
            color="secondary"
            ref={cpaRef}
            //value={Web3Utils.toEther(filters?.current_pool_amount?.min.toString())}
            defaultValue={0}
            //onChange={(_, newValue) => handleFiltersChanged({ current_pool_amount: { min: Web3Utils.toWei(newValue.toString()), strict: false } })}
          />
        </Stack>
        <Stack
        //spacing={1}
        >
          <Typography variant="button">Initial Pool Amount</Typography>
          <Slider
            sx={{
              width: "98%",
            }}
            color="secondary"
            //ref={ipaRef}
            value={Web3Utils.toEther(
              filters?.initial_pool_in_wei?.min.toString()
            )}
            defaultValue={0}
            onChange={(_, newValue) =>
              setFilters({
                ...filters,
                initial_pool_in_wei: {
                  min: Web3Utils.toWei(newValue.toString()),
                  strict: false,
                },
              })
            }
          />
        </Stack>
      </Stack>
    </Container>
  );

  return (
    <>
      {/* <ZoomOnScroll
                showFilters={showFilters}
            >
                <Fab
                    aria-label="filter"
                    sx={{
                        position: 'fixed',
                        bottom: '12%',
                        left: '75%',
                        display: {
                            xs: 'flex',
                            md: 'none'
                        },
                        zIndex: 1
                    }}
                    onClick={handleFiltersShow}
                >
                    <FilterAltIcon />
                </Fab>
            </ZoomOnScroll> */}
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          //position: 'relative',
          minWidth: "25%",
          minHeight: "100vh",
          //maxWidth: '20rem',
          borderRight: "1px solid #442d76",
          background: "#1C1035",
          px: 3,
          py: 3,
        }}
      >
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              sx={{
                //mt: 2,
                /* fontSize: 20,*/
                fontWeight: 600,
              }}
              textTransform="uppercase"
            >
              Filters
            </Typography>
            <Button
              variant="contained"
              disableElevation
              sx={{
                letterSpacing: 1,
                fontSize: 16,
                background: (theme) => theme.palette.primary.light + "90",
              }}
              onClick={() => onSubmit(filters)}
            >
              APPLY FILTERS
            </Button>
          </Stack>
          {/* <Box
                        sx={{
                            overflow: "auto",
                            maxHeight: "100vh"
                        }}
                    > */}
          <Stack spacing={1}>
            <Typography variant="h6">Choose Participation Assets</Typography>
            {/* <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    sx={{
                                        color: (theme) => theme.palette.primary.light,
                                        '&.Mui-checked': {
                                            color: (theme) => theme.palette.secondary.main,
                                        },
                                    }}
                                />}
                                label="WMATIC"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    sx={{
                                        color: (theme) => theme.palette.primary.light,
                                        '&.Mui-checked': {
                                            color: (theme) => theme.palette.secondary.main,
                                        },
                                    }}
                                />}
                                label="DAI"
                            />
                        </FormGroup> */}
            <FilterAssets
              assets={filters?.asset}
              onAssetToggled={(new_assets) =>
                setFilters({ ...filters, asset: new_assets })
              }
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="button">Participants</Typography>
            {/* <Slider
              sx={{
                width: "98%",
              }}
              value={filters?.participants?.min}
              color="secondary"
              defaultValue={0}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `${value} participants`}
              onChange={(_, newValue) =>
                setFilters({
                  ...filters,
                  participants: { min: newValue, strict: false },
                })
              } */}
            <ResponsiveRangeInput
              updateCritera={(minValue, maxValue) => {
                filters.participants.min = minValue;
                filters.participants.max = maxValue;
                if (
                  filters.participants.max &&
                  filters.participants.min > filters.participants.max
                ) {
                  filters.participants.min = 0;
                  delete filters.participants.max;
                }
              }}
              placeholders={["Min Participants", "Max Participants"]}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="button"> Joining Fees</Typography>
            {/* <Slider
              sx={{
                width: "98%",
              }}
              value={Web3Utils.toEther(
                filters?.joining_fees_in_wei?.min.toString()
              )}
              color="secondary"
              defaultValue={0}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `${value} Joining Fees (in ETH)`}
              onChange={(_, newValue) =>
                setFilters({
                  ...filters,
                  joining_fees_in_wei: {
                    min: Web3Utils.toWei(newValue.toString()),
                    strict: false,
                  },
                })
              }
            /> */}
            <ResponsiveRangeInput
              updateCritera={(minValue, maxValue) => {
                filters.joining_fees_in_wei.min = minValue;
                filters.joining_fees_in_wei.max = maxValue;
                if (
                  filters.joining_fees_in_wei.max &&
                  filters.joining_fees_in_wei.min >
                    filters.joining_fees_in_wei.max
                ) {
                  filters.joining_fees_in_wei.min = 0;
                  delete filters.joining_fees_in_wei.max;
                }
              }}
              placeholders={["Min Joining Fees", "Max Joining Fees"]}
              prefix={true}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="button">Current Pooled Amount</Typography>
            {/* <Slider
              sx={{
                width: "98%",
              }}
              value={Web3Utils.toEther(
                filters?.current_pool_amount?.min.toString()
              )}
              color="secondary"
              defaultValue={0}
              valueLabelDisplay="auto"
              getAriaValueText={(value) =>
                `${value} Current Pooled Amount (in ETH)`
              }
              onChange={(_, newValue) =>
                setFilters({
                  ...filters,
                  current_pool_amount: {
                    min: Web3Utils.toWei(newValue.toString()),
                    strict: false,
                  },
                })
              }
            /> */}
            <ResponsiveRangeInput
              updateCritera={(minValue, maxValue) => {
                filters.current_pool_amount.min = minValue;
                filters.current_pool_amount.max = maxValue;
                if (
                  filters.current_pool_amount.max &&
                  filters.current_pool_amount.min >
                    filters.current_pool_amount.max
                ) {
                  filters.current_pool_amount.min = 0;
                  delete filters.current_pool_amount.max;
                }
              }}
              placeholders={[
                "Min Asset Current Amount",
                "Max  Asset Current Amount",
              ]}
              prefix={true}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="button">Initial Invested Amount</Typography>
            {/* <Slider
              sx={{
                width: "98%",
              }}
              value={Web3Utils.toEther(
                filters?.initial_pool_in_wei?.min.toString()
              )}
              color="secondary"
              defaultValue={0}
              valueLabelDisplay="auto"
              getAriaValueText={(value) =>
                `${value} Initial Invested Amount (in ETH)`
              }
              onChange={(_, newValue) =>
                setFilters({
                  ...filters,
                  initial_pool_in_wei: {
                    min: Web3Utils.toWei(newValue.toString()),
                    strict: false,
                  },
                })
              }
            /> */}
            <ResponsiveRangeInput
              updateCritera={(minValue, maxValue) => {
                filters.initial_pool_in_wei.min = minValue;
                filters.initial_pool_in_wei.max = maxValue;
                if (
                  filters.initial_pool_in_wei.max &&
                  filters.initial_pool_in_wei.min >
                    filters.initial_pool_in_wei.max
                ) {
                  filters.initial_pool_in_wei.min = 0;
                  delete filters.initial_pool_in_wei.max;
                }
              }}
              placeholders={[
                "Min Asset Initial Amount",
                "Max Asset Initial Amount",
              ]}
              prefix={true}
            />
          </Stack>
          {/* </Box> */}
        </Stack>
      </Box>
      <Drawer
        anchor="bottom"
        elevation={10}
        open={showFilters}
        variant="temporary"
        onClose={handleFiltersShow}
        prefix={true}
        sx={{
          "-webkit-backdrop-filter": "blur(4px)",
          backdropFilter: "blur(4px)",
          //maxHeight: '98%'
        }}
        PaperProps={{
          sx: {
            background: "transparent",
          },
        }}
      >
        <FiltersDrawer />
      </Drawer>
    </>
  );
}
