import Web3Utils from "../utils/web3.utils";
import IPFSUtils from "../utils/ipfs.utils";
import CreateTournamentFactory from "../dApp/abi/CreateTournamentFactory.json";
import CreateTournament from "../dApp/abi/CreateTournament.json";
import IERC20 from "../dApp/abi/IERC20.json";
import Assets from "../dApp/erc20.js";
import ContractsJSON from "../dApp/contract.fn.json";
import Reducers from "../API/Reducers";

function createTournament(
  ipfs_data,
  details = {
    tournamentStart: 0,
    tournamentEnd: 0,
    tournamentEntryFees: 0,
    initial_pool: 0,
    asset: "matic",
  },
  progress_callback = () => {}
) {
  return new Promise(async (resolve, reject) => {
    try {
      const ipfs_cid = await IPFSUtils.add(ipfs_data);
      const tournamentURI = IPFSUtils.getURI(ipfs_cid);
      const asset_details =
        Assets[process.env.REACT_APP_MAINNET]["assets"][details.asset];

      if (asset_details) {
        const asset_address = asset_details["address"]["token-address"];
        const {
          tournamentStart,
          tournamentEnd,
          tournamentEntryFees,
          initial_pool,
        } = details;

        const aAssetAddress = asset_details["address"]["aave-token-address"];
        const isNativeAsset = asset_details["isNativeAsset"];
        let result;

        if (isNativeAsset) {
          result = await Web3Utils.sendSpecial(
            CreateTournamentFactory.abi,
            getLatestFactoryContractAddress(),
            ContractsJSON.createTournament,
            { value: initial_pool },
            1,
            // tournamentURI,
            tournamentStart,
            tournamentEnd,
            tournamentEntryFees,
            //lending_pool_address,
            asset_address,
            initial_pool,
            aAssetAddress,
            isNativeAsset
          );
        } else {
          let success = await Web3Utils.sendSpecial(
            IERC20.abi,
            asset_address,
            "approve",
            null,
            1,
            getLatestFactoryContractAddress(),
            initial_pool
          );
          if (success) {
            result = await Web3Utils.send(
              CreateTournamentFactory.abi,
              ContractsJSON.createTournament,
              //   tournamentURI,
              tournamentStart,
              tournamentEnd,
              tournamentEntryFees,
              //lending_pool_address,
              asset_address,
              initial_pool,
              aAssetAddress,
              isNativeAsset
            );
          } else throw "Approval failed";
        }

        resolve(
          result.events["TournamentCreated"].returnValues.tournamentAddress
        );
      } else {
        reject("Invalid asset");
      }
    } catch (err) {
      reject(err);
    }
  });
}

function getLatestFactoryContractAddress() {
  return ContractsJSON.factory_address[
    ContractsJSON.factory_address.length - 1
  ];
}

function joinTournament(
  { contract_address, joining_fees_in_wei, asset, isNativeAsset },
  progress_callback = () => {}
) {
  return new Promise(async (resolve, reject) => {
    try {
      if (isNativeAsset) {
        await Web3Utils.sendSpecial(
          CreateTournament.abi,
          contract_address,
          ContractsJSON.joinTournament,
          { value: joining_fees_in_wei },
          1
        );
      } else {
        const asset_details =
          Assets[process.env.REACT_APP_MAINNET]["assets"][asset];
        if (asset_details) {
          const asset_address = asset_details["address"]["token-address"];
          let success = await Web3Utils.sendSpecial(
            IERC20.abi,
            asset_address,
            "approve",
            null,
            2,
            contract_address,
            joining_fees_in_wei
          );
          if (success) {
            await Web3Utils.sendSpecial(
              CreateTournament.abi,
              contract_address,
              ContractsJSON.joinTournament,
              {},
              1
            );
          } else throw "Approval failed";
        } else {
          throw "Unsupported asset";
        }
      }
      resolve();
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

function withdrawFunds(
  { percentage, contract_address },
  account,
  progress_callback = () => {}
) {
  return new Promise(async (resolve, reject) => {
    try {
      const signature = await signMessage(
        Math.floor(percentage * 100).toString()
      );
      const chainId = await Web3Utils.getChainID();
      /* const isComplete = await Web3Utils.callVarSpecial(CreateTournament.abi, contract_address, 'isCompleted')
            if (!isComplete) {
                const tournamentUpdate = Web3Utils.getMethod(CreateTournament.abi, contract_address, ContractsJSON.setCompletionStatus)
                const signedUpdateTxn = await Web3Utils.signTransaction(
                    {
                        address: contract_address,
                        data: Web3Utils.encodeABI(tournamentUpdate),
                        gas: await Web3Utils.estimateTxnGas(tournamentUpdate),
                        chainId,
                        privateKey: process.env.REACT_APP_ADMIN_PRIVATE_KEY
                    }
                )
                await Web3Utils.sendSignedTransaction(signedUpdateTxn);
            } */

      const txn = Web3Utils.getMethod(
        CreateTournament.abi,
        contract_address,
        ContractsJSON.setParticipantReward,
        Math.floor(percentage * 100),
        //Web3Utils.account
        account
      );
      const gas = await Web3Utils.estimateTxnGas(txn);
      const data = Web3Utils.encodeABI(txn);
      // const signedTxn = await Web3Utils.signTransaction({
      //   address: contract_address,
      //   data,
      //   gas,
      //   chainId,
      //   privateKey: process.env.REACT_APP_ADMIN_PRIVATE_KEY,
      // });
      // await Web3Utils.sendSignedTransaction(signedTxn);
      await Reducers.sendTransaction({
        address: contract_address,
        data,
        gas,
        chainId,
      });

      await Web3Utils.sendSpecial(
        CreateTournament.abi,
        contract_address,
        ContractsJSON.withdrawFunds,
        null,
        2,
        signature
      );

      resolve();
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

function signMessage(string) {
  return new Promise(async (resolve, reject) => {
    try {
      var msg = await Web3Utils.getKeccak256Hash(string);
      resolve(await Web3Utils.sign(msg));
    } catch (err) {
      reject(err);
    }
  });
}

export default {
  createTournament,
  getLatestFactoryContractAddress,
  joinTournament,
  withdrawFunds,
};
