import React, { useEffect, useState } from "react";
import Base from "./Base";
import Buy from "./Buy";
import Sell from "./Sell";
import Tabs from "./Tabs";
import "../style.css";
import { usePrice } from "../../../hooks/web3.hooks";
import axios from "axios";
import Reducers from "../../../API/Reducers";
import TimeExpired from "./TimeExpired";
import { useLocation, useNavigate } from "react-router-dom";
import DateUtils from "../../../utils/date.utils";

export default function Transaction({
  tab,
  crypto,
  vestibule_id,
  tradable_coins,
  onCoinSwitched,
  onTransactionCompleted,
  open,
  eventId,
}) {
  const [tabs, setTabs] = useState(tab ? tab : 0);
  const [currentCrypto, setCurrentCrypto] = useState(crypto);
  const [fetching, setFetching] = useState(false);
  const [isEventFinished, setEventFinished] = useState(false);
  const location = useLocation();
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();

  const handleTabsChanged = (index) => {
    setTabs(index);
  };

  const blockNumber = usePrice();

  useEffect(() => {
    async function getData() {
      setFetching(true);
      const res = await axios.get(
        `${process.env.REACT_APP_COIN_GECKO_BASE_URL}/coins/${
          crypto?.id
        }?x_cg_pro_api_key=${
          process.env.REACT_APP_COIN_GECKO_API_KEY
        }&timestamp=${new Date()}`,
        {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );
      console.log("current", res);
      setCurrentCrypto(res.data);
      setFetching(false);
    }
    getData();
  }, [blockNumber]);

  useEffect(() => {
    async function getEventDetails() {
      const { end_date } = await Reducers.getEvent(eventId);

      if (
        Number(DateUtils.toUnix(new Date().toUTCString())) > Number(end_date)
      ) {
        setEventFinished(true);
      }
    }

    getEventDetails();
  }, [tabs]);

  useEffect(() => {
    setCurrentCrypto(crypto);
  }, [crypto]);

  return (
    <Base>
      <Tabs
        onTabsChanged={handleTabsChanged}
        opts={["Buy", "Sell"]}
        tab={tab}
      />
      {isEventFinished ? (
        <TimeExpired
          open={true}
          redirect={location.pathname !== "/"}
          time={10000}
          onClose={() => (location.pathname === "/" ? setModal(null) : null)}
          wallet={"metamask"}
          onTimeout={() => {
            navigate("/");
            setModal(null);
          }}
        />
      ) : tabs === 0 ? (
        <Buy
          onCoinSwitched={onCoinSwitched}
          tradable_coins={tradable_coins}
          open={open}
          crypto={currentCrypto}
          onTransactionCompleted={onTransactionCompleted}
          vestibule_id={vestibule_id}
          fetching={fetching}
        />
      ) : (
        <Sell
          onTransactionCompleted={onTransactionCompleted}
          tradable_coins={tradable_coins}
          open={open}
          onCoinSwitched={onCoinSwitched}
          crypto={currentCrypto}
          vestibule_id={vestibule_id}
          fetching={fetching}
        />
      )}
    </Base>
  );
}
