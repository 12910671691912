import {
  Box,
  Grid,
  Avatar,
  CardActionArea,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";
import React from "react";
import ERC20 from "../../dApp/erc20.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function FilterAssets({ assets, onAssetToggled }) {
  const AssetCard = ({ name, image, enabled }) => (
    <Card
      sx={{
        background: (theme) => theme.palette.primary.darker,
        boxShadow: (theme) =>
          enabled
            ? `inset 0px 0px 0px 2px ${theme.palette.secondary.main};`
            : "none",
        //boxShadow: (theme) => isActive ? `inset 0px 0px 0px 2px ${theme.palette.secondary.main};` : 'none',
      }}
      onClick={() =>
        onAssetToggled(
          assets?.includes(name.toLowerCase())
            ? assets?.filter((i) => i !== name.toLowerCase())
            : [...assets, name.toLowerCase()]
        )
      }
      elevation={10}
    >
      <CardActionArea>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/*  {
                            loading ?
                                <>
                                    <Stack sx={{
                                        width: '100%'
                                    }}
                                    >
                                        <Skeleton
                                            variant='text'
                                            height='40px'
                                            width='70%'
                                            animation='wave'
                                        />
                                        <Skeleton
                                            variant='text'
                                            height='25px'
                                            width='40%'
                                            animation='wave'
                                        />
                                    </Stack>
                                    <Skeleton
                                        variant='circular'
                                        height='40px'
                                        width='48px'
                                        animation='wave'
                                    />
                                </>
                                : */}
            <>
              <Stack spacing={0.5}>
                {name.toLowerCase() === "matic" ? (
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        fontSize: 16,
                      }}
                    >
                      {name}
                    </Typography>
                    {/* <Recommended /> */}
                  </Stack>
                ) : (
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontSize: 16,
                    }}
                  >
                    {name}
                  </Typography>
                )}
                {/* <Typography
                                    variant='caption'
                                    sx={{
                                        opacity: 0.8,
                                    }}
                                    textTransform='uppercase'
                                    fontWeight='light'
                                >
                                    24 results
                                </Typography> */}
              </Stack>
              <Avatar
                src={image}
                sx={{
                  height: "35px",
                  width: "35px",
                  backgroundColor: (theme) =>
                    theme.palette.primary.light + "50",
                }}
              />
            </>
            {/* } */}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Box
      sx={{
        pr: 1,
      }}
    >
      <Grid container spacing={1}>
        {Object.keys(ERC20[process.env.REACT_APP_MAINNET]["assets"]).map(
          (key) => (
            <Grid item xs={12 / 2}>
              <AssetCard
                enabled={assets?.includes(key)}
                name={key.toUpperCase()}
                image={
                  ERC20[process.env.REACT_APP_MAINNET]["assets"][key]["image"]
                }
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
}
