import { Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import Base from "../Base";

export default function WalletUnsupportedChain({
  open,
  onClose,
  onDisconnect,
  onChangeChain,
}) {
  return (
    <Base open={open} onClose={onClose}>
      <Stack
        spacing={1}
        sx={{
          width: 270,
        }}
      >
        <Typography
          variant="button"
          sx={{
            fontSize: 22,
          }}
        >
          Unsupported Network Detected
        </Typography>
        <Typography variant="body2" color="GrayText">
          Looks like you connected to an unsupported network. We currently only
          operate on the Polygon Mainnet network. Please change your network to
          Polygon Mainnet.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
            mt: 2,
          }}
          onClick={onChangeChain}
        >
          Connect to Polygon Mainnet
        </Button>
        <Stack>
          <Divider>
            <Typography>Or</Typography>
          </Divider>
          <Button
            variant="contained"
            fullWidth
            sx={{
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.light,
              },
              mt: 2,
            }}
            onClick={onDisconnect}
          >
            Disconnect
          </Button>
        </Stack>
      </Stack>
    </Base>
  );
}
