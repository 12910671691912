import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import analytics from "../../firebase/TrackPathForAnalytics";
import { logEvent } from "firebase/analytics";

export default function TrackAnalytics({ children }) {
  let location = useLocation();
  useEffect(() => {
    // const analytics = window.firebase && window.firebase.analytics;

    const page_path = location.pathname + location.search;

    logEvent(analytics, "screen_view", {
      firebase_screen: page_path,
      //firebase_screen_class: screenClass,
    });

    //analytics().setCurrentScreen(page_path);
    //analytics().logEvent("page_view", { page_path });
  }, [location]);
  return children;
}
