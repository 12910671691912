import React, { useState } from "react";
import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import ERC20 from "../../dApp/erc20.js";
import Web3Utils from "../../utils/web3.utils";
import DateUtils from "../../utils/date.utils";
import Avatars from "../Events/Avatars";
import HoverUp from "../Animations/HoverUp";
import TradeableCoins from "../Modals/Event/TradeableCoins";
import { useWeb3React } from "@web3-react/core";
import { ReactComponent as JoiningFees } from "../../icons/joining-fees.svg";
import { ReactComponent as InitialContribution } from "../../icons/initial-contribution.svg";
import { ReactComponent as Participants } from "../../icons/participants.svg";
import { ReactComponent as InitialPool } from "../../icons/initial-pool.svg";

export default function Event({ event, onClick, onJoinClicked }) {
  const { active } = useWeb3React();
  const {
    event_id,
    title,
    initial_pool_in_wei,
    current_pool_amount,
    joining_fees_in_wei,
    participants,
    start_date,
    end_date,
    asset,
    status,
    tradable_coins,
    is_joined,
    official_event,
  } = event;

  const asset_details = ERC20[process.env.REACT_APP_MAINNET]["assets"][asset];

  const [modal, setModal] = useState({
    open: false,
    coins: [],
  });

  const handleViewTradeableCoins = (e, coins) => {
    if (e) {
      if (e?.target?.classList?.contains("more_coins")) {
        e.stopPropagation();
        setModal({
          open: !modal.open,
          coins: modal.coins.length > 0 ? [] : coins,
        });
      }
    } else {
      setModal({
        open: !modal.open,
        coins: modal.coins.length > 0 ? [] : coins,
      });
    }
  };

  const closeTradeableCoins = () => {
    setModal({
      open: false,
      coins: [],
    });
  };

  return (
    <Box
      sx={{
        height: {
          md: "100%",
          xs: "auto",
        },
        display: "flex",
      }}
    >
      <TradeableCoins
        open={modal.open}
        onClose={closeTradeableCoins}
        coins={tradable_coins?.map((i) => {
          return {
            crypto_id: i.crypto_id,
            "coin.crypto_image": i.crypto_image,
            "coin.crypto_symbol": i.crypto_symbol,
            "coin.crypto_name": i.crypto_name,
          };
        })}
      />
      <HoverUp>
        <Paper
          sx={{
            p: 2,
            background: (theme) => theme.palette.primary.darker /* + "90" */,
            m: 0.5,
            flex: 1,
            //width: '100%'
            borderColor: (theme) =>
              official_event && theme.palette.secondary.main,
          }}
          variant="outlined"
          elevation={10}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              height: "100%",
            }}
          >
            <Box onClick={onClick}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Avatar
                  src={ERC20["polygon"]["assets"][asset]["image"]}
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: (theme) =>
                      theme.palette.primary.light + "50",
                    p: 0.5,
                  }}
                />
                <Stack width="100%">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center  "
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="#d5d5d5"
                      sx={{
                        fontSize: {
                          xs: 10,
                          md: 12,
                        },
                      }}
                    >
                      {DateUtils.format(DateUtils.unixToDate(start_date))} -{" "}
                      {DateUtils.format(DateUtils.unixToDate(end_date))}
                    </Typography>
                    <Stack
                      spacing={1}
                      direction={{
                        xs: "column",
                        xl: "row",
                      }}
                    >
                      {official_event && (
                        <Paper
                          sx={{
                            px: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: (theme) => {
                              return theme.palette.secondary.main + "90";
                            },
                          }}
                        >
                          <Typography
                            variant="button"
                            color="#fff"
                            sx={{
                              fontSize: {
                                xs: 10,
                                md: 12,
                              },
                            }}
                          >
                            Official Admin Event
                          </Typography>
                        </Paper>
                      )}
                      <Paper
                        sx={{
                          px: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: (theme) =>
                            status === "completed"
                              ? theme.palette.misc.error + "90"
                              : status === "ongoing"
                              ? theme.palette.misc.success + "90"
                              : theme.palette.misc.warning,
                        }}
                      >
                        <Typography
                          variant="button"
                          color="#fff"
                          sx={{
                            fontSize: {
                              xs: 10,
                              md: 12,
                            },
                          }}
                        >
                          {status}
                        </Typography>
                      </Paper>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex",
                      },
                    }}
                  >
                    <Typography
                      variant="button"
                      color="#fff"
                      noWrap
                      textOverflow="ellipsis"
                      sx={{
                        fontSize: 16,
                        width: "15rem",
                      }}
                    >
                      {title}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <JoiningFees
                        fill="white"
                        stroke="#A4A4A4"
                        height={28}
                        width={28}
                      />
                      <Typography
                        variant="button"
                        color="#fff"
                        sx={{
                          fontSize: 16,
                        }}
                      >
                        {Number(asset_details.toEther(joining_fees_in_wei)) > 0
                          ? `${asset_details.toEther(
                              joining_fees_in_wei
                            )} ${asset.toUpperCase()}`
                          : "FREE"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack spacing={0.2}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="button"
                        color="#fff"
                        noWrap
                        textOverflow="ellipsis"
                        sx={{
                          fontSize: 16,
                          width: "8rem",
                          display: {
                            xs: "block",
                            md: "none",
                          },
                        }}
                      >
                        {title}
                      </Typography>
                      <Avatars
                        sx={{
                          display: {
                            xs: "flex",
                            md: "none",
                          },
                        }}
                        images={[
                          ...tradable_coins
                            ?.slice(0, 5)
                            ?.map((item) => item["crypto_image"]),
                        ]}
                      />
                    </Stack>
                    <Stack alignItems="start">
                      <Stack
                        sx={{
                          mt: 1,
                        }}
                        width="100%"
                      >
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          alignItems="center"
                          width="100%"
                        >
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                          >
                            <InitialContribution
                              height={28}
                              width={28}
                              fill="white"
                              stroke="#A4A4A4"
                            />
                            <Typography variant="body2" color="#d5d5d5">
                              {asset_details.toEther(initial_pool_in_wei)}{" "}
                              {asset.toUpperCase()}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{
                              display: {
                                xs: "flex",
                                md: "none",
                              },
                            }}
                          >
                            <JoiningFees
                              fill="white"
                              stroke="#A4A4A4"
                              height={28}
                              width={28}
                            />
                            <Typography
                              variant="body2"
                              color="#fff"
                              fontWeight={600}
                            >
                              {Number(
                                asset_details.toEther(joining_fees_in_wei)
                              ) > 0
                                ? `${asset_details.toEther(
                                    joining_fees_in_wei
                                  )} ${asset.toUpperCase()}`
                                : "FREE"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{
                              display: {
                                xs: "none",
                                md: "flex",
                              },
                            }}
                          >
                            <Avatars
                              images={[
                                ...tradable_coins
                                  ?.slice(0, 5)
                                  ?.map((item) => item["crypto_image"]),
                              ]}
                            />
                            <Typography
                              variant="body2"
                              color="#fff"
                              sx={{
                                display: {
                                  xs: "none",
                                  md: "block",
                                },
                              }}
                            >
                              {tradable_coins.length === 0 ? (
                                `NO COINS TRADEABLE`
                              ) : tradable_coins.length - 5 > 0 ? (
                                <span
                                  className="more_coins"
                                  onClick={(e) =>
                                    handleViewTradeableCoins(e, tradable_coins)
                                  }
                                >
                                  {`+${tradable_coins.length - 5} MORE`}
                                </span>
                              ) : null}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          width="100%"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Participants
                              fill="white"
                              stroke="#A4A4A4"
                              height={26}
                              width={26}
                            />
                            <Typography variant="body2" color="#d5d5d5">
                              {participants}&nbsp;
                              {participants === 1
                                ? "PARTICIPANT"
                                : "PARTICIPANTS"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <InitialPool
                              fill="white"
                              stroke="#A4A4A4"
                              height={26}
                              width={26}
                            />
                            <Typography
                              variant="body2"
                              color="#fff"
                              fontWeight={600}
                            >
                              {current_pool_amount != 0
                                ? Number(
                                    asset_details.toEther(current_pool_amount)
                                  ).toFixed(2)
                                : "0"}{" "}
                              {asset.toUpperCase()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{
                mt: 2,
              }}
            >
              {active && (
                <Button
                  variant="contained"
                  fullWidth
                  disabled={is_joined || status === "completed"}
                  onClick={onJoinClicked}
                  /* sx={{
                                    "&:hover": {
                                        backgroundColor: (theme) => theme.palette.primary.light,
                                    },
                                }} */
                >
                  {status === "completed"
                    ? "Event completed"
                    : is_joined
                    ? "JOINED"
                    : "JOIN EVENT"}
                </Button>
              )}
            </Stack>
          </Stack>
        </Paper>
      </HoverUp>
    </Box>
  );
}
