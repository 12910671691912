/**
 * The main data structure for ERC20 token manipulations
 * Please do not change any key value
 * You may change the pair value according to your needs
 * You can change the key value but make sure wherever the key is being used, you update them there too
 *
 * The main "mumbai" object key is related to the REACT_APP_MAINNET env variable
 * If you need to change the network name, change it both here as well as the env variable mentioned above
 */

import { client as web3 } from "./web3";

const getWei = (value, decimals) => {
  value = value.toString();
  let numberDecimals = web3.utils.toBN(countDecimals(value));
  let formattedInput = value.split(".")[0];
  if (numberDecimals > 0) formattedInput = value.split(".").join("");
  decimals = web3.utils.toBN(decimals);
  let amount = web3.utils.toBN(formattedInput);
  value = amount.mul(web3.utils.toBN(10).pow(decimals));
  return value.div(web3.utils.toBN(10).pow(numberDecimals)).toString();
  //web3.utils.toBN(value).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals))).toString()
};

const getEther = (value, decimals) =>
  (value / Math.pow(10, decimals)).toString();

const countDecimals = function (value) {
  if (Math.floor(parseFloat(value)) === parseFloat(value)) return 0;
  return value.toString().split(".")[1].length || 0;
};

export default {
  polygon: {
    "lending-pool-address-provider":
      "0x178113104fEcbcD7fF8669a0150721e231F0FD4B",
    "gateway-aave": "0xee9eE614Ad26963bEc1Bec0D2c92879ae1F209fA",
    assets: {
      matic: {
        address: {
          "aave-token-address": "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
          "token-address": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          "unwrapped-token-address":
            "0x0000000000000000000000000000000000001010",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/14073/large/matic.png?1628852392",
        isNativeAsset: true,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      dai: {
        address: {
          "aave-token-address": "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
          "token-address": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/9956/large/4943.png?1636636734",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      usdc: {
        address: {
          "aave-token-address": "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
          "token-address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        },
        decimals: 6,
        image:
          "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      usdt: {
        address: {
          "aave-token-address": "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
          "token-address": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        },
        decimals: 6,
        image:
          "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      wbtc: {
        address: {
          "aave-token-address": "0x078f358208685046a11C85e8ad32895DED33A249",
          "token-address": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
        },
        decimals: 8,
        image:
          "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
      weth: {
        address: {
          "aave-token-address": "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
          "token-address": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        },
        decimals: 18,
        image:
          "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
        isNativeAsset: false,
        toWei: function (value) {
          return getWei(value, this.decimals);
        },
        toEther: function (value) {
          return getEther(value, this.decimals);
        },
      },
    },
    image:
      "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
    isNativeAsset: false,
    alias: {
      weth: "weth",
      dai: "dai",
      "wrapped-bitcoin": "wbtc",
      tether: "usdt",
      "matic-network": "matic",
      "usd-coin": "usdc",
    },
    yieldSources: ["AAVE"],
  },
};
