const HOST_URL =
  process.env.REACT_APP_NODE_ENV === "local"
    ? "http://localhost:3001/"
    : "https://api.bricksprotocol.com/";
const API_VERSION = "api/v1";

const BASE_URL = HOST_URL + API_VERSION;

const EVENTS_BASE = BASE_URL + "/events"; //gets all events
const USERS_BASE = BASE_URL + "/users"; // users base
const EVENT_DETAILS = BASE_URL + "/events/details"; // gets details of an event
const MY_EVENTS = BASE_URL + "/events/my_events";
const LOGIN = USERS_BASE + "/login";
const TRANSACT_BASE = BASE_URL + "/transact";
const WITHDRAWN_VESTIBULES_BASE = BASE_URL + "/withdrawnVestibules";

const BALANCE = TRANSACT_BASE + "/balance";
const TRANSACTIONS = TRANSACT_BASE + "/transactions";
const BUY = TRANSACT_BASE + "/buy";
const SELL = TRANSACT_BASE + "/sell";
const PORTFOLIO = BASE_URL + "/portfolio";
const CRYPTO_BALANCE = TRANSACT_BASE + "/crypto_balance";

const COINS = BASE_URL + "/coins";

const CREATE_EVENT = EVENTS_BASE + "/add";
const WITHDRAWABLE_EVENTS = EVENTS_BASE + "/withdrawable";

const JOIN_EVENT = EVENTS_BASE + "/join";

const EVENTS_VESTIBULES_BASE = BASE_URL + "/vestibules";
const WITHDRAW = WITHDRAWN_VESTIBULES_BASE + "/withdrawn";

const EVENTS_VESTIBULE_DETAILS = EVENTS_VESTIBULES_BASE + "/details";
const VESTIBULE_DETAILS = EVENTS_VESTIBULES_BASE + "/vestibuleDetails";

const LEADERBOARDS = EVENTS_BASE + "/leaderboards";

const CONTRACTS_BASE = BASE_URL + "/contracts";
const SEND_TXN = CONTRACTS_BASE + "/sendTxn";

export default {
  EVENTS_BASE,
  LOGIN,
  EVENT_DETAILS,
  COINS,
  MY_EVENTS,
  CREATE_EVENT,
  JOIN_EVENT,
  TRANSACTIONS,
  PORTFOLIO,
  EVENTS_VESTIBULE_DETAILS,
  VESTIBULE_DETAILS,
  LEADERBOARDS,
  BALANCE,
  BUY,
  SELL,
  CRYPTO_BALANCE,
  WITHDRAWABLE_EVENTS,
  WITHDRAW,
  SEND_TXN,
};
