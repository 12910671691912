import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { cssNumber } from "jquery";
import React, { useEffect, useState } from "react";
import { usePrice } from "../../hooks/web3.hooks";
import { useWindowDimensions } from "../EventDetails/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function CryptosInfo({
  data,
  toggleDrawer,
  setLoading,
  setCryptoInfo,
  setDrawerType,
  height,
  cryptoData,
  toggleCryptoDrawer,
}) {
  // const [loading, setLoading] = useState(true);
  const [crypto, setCrypto] = useState();
  const { width, i } = useWindowDimensions();
  //const block = usePrice()

  useEffect(() => {
    // setId(data && data.crypto_id ? data.crypto_id : "bitcoin");
    const fetchCrypto = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_COIN_GECKO_BASE_URL}/coins/${data.crypto_id}?x_cg_pro_api_key=${process.env.REACT_APP_COIN_GECKO_API_KEY}`
      );
      console.log("info", res);
      setCrypto(res.data);
      setCryptoInfo(res.data);
      setLoading(false);
    };

    if (data?.crypto_id) fetchCrypto();
  }, [data]);

  useEffect(() => {
    setCrypto({
      ...crypto,
      crypto_current_price: cryptoData?.crypto_current_price,
      crypto_price_change_percent_24h:
        cryptoData?.crypto_price_change_percent_24h,
    });
  }, [cryptoData]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #553398",
          width: "100%",
          height: height ? height : "50vh",
          background: {
            xs: "#0005",
            xl: "none",
          },
          alignItems: width < 1200 ? "center" : "flex-start",
          justifyContent: width < 1200 ? "center" : "flex-start",
        }}
      >
        {/* {loading ? (
					<Typography variant="button" sx={{ fontSize: 24 }}>
						Loading
					</Typography>
				) : ( */}
        <>
          {crypto &&
          crypto.name &&
          crypto?.crypto_current_price &&
          crypto.market_data.price_change_24h_in_currency.usd &&
          crypto?.crypto_price_change_percent_24h ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 2,
                  py: 1,
                }}
              >
                <img
                  src={
                    crypto && crypto.image && crypto.image.large
                      ? crypto.image.large
                      : "unknown"
                  }
                  style={{ height: 30, width: 30 }}
                />
                <Typography variant="button" sx={{ ml: 1, fontSize: 16 }}>
                  {crypto && crypto.symbol ? crypto.symbol : "unknown  "} USD
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  px: 2,
                }}
              >
                <Typography sx={{ opacity: 0.5 }}>
                  {crypto && crypto.name ? crypto.name : "unknown"} / U.S.
                  Dollar
                </Typography>
                <Typography
                  sx={{ fontSize: 26, fontWeight: "bold", letterSpacing: 1 }}
                >
                  {/* {crypto?.market_data?.current_price?.usd
								? Number(
									Number(crypto.market_data.current_price.usd).toFixed(2)
								).toLocaleString()
								: "unknown"}{" "} */}
                  {crypto?.crypto_current_price
                    ? Number(Number(crypto?.crypto_current_price).toFixed(8))
                    : "unknown"}{" "}
                  <span style={{ fontSize: 12, opacity: 0.5 }}>USD</span>
                </Typography>
                <Typography
                  variant="button"
                  sx={{ fontSize: 16, letterSpacing: 1 }}
                >
                  {crypto?.market_data?.price_change_24h_in_currency?.usd
                    ? Number(
                        Number(
                          crypto.market_data.price_change_24h_in_currency.usd
                        ).toFixed(8)
                      )
                    : "unknown"}{" "}
                  <span style={{ opacity: 0.5, marginLeft: 5 }}>
                    (
                    {crypto?.crypto_price_change_percent_24h
                      ? `${Number(
                          crypto?.crypto_price_change_percent_24h
                        ).toFixed(2)}%`
                      : "unknown"}
                    )
                  </span>
                </Typography>
                {width < 1200 ? (
                  <Button
                    sx={{
                      height: 40,
                      width: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: (theme) => theme.palette.primary.light,
                      mt: 2,
                      borderRadius: 2,
                      "&:hover": {
                        background: (theme) =>
                          theme.palette.primary.light + "99",
                      },
                    }}
                    onClick={toggleCryptoDrawer}
                  >
                    <Typography variant="button" color="white">
                      Know more
                    </Typography>
                  </Button>
                ) : (
                  <Box>
                    <Typography sx={{ mt: 2 }}>Key Stats</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Typography sx={{ opacity: 0.5 }} variant="body2">
                        Volume
                      </Typography>
                      <Typography sx={{}} variant="body2">
                        {crypto &&
                        crypto.market_data &&
                        crypto.market_data.total_volume &&
                        crypto.market_data.total_volume.usd
                          ? Number(
                              crypto.market_data.total_volume.usd
                            ).toLocaleString()
                          : "unknown"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Typography sx={{ opacity: 0.5 }} variant="body2">
                        Market Cap
                      </Typography>
                      <Typography sx={{}} variant="body2">
                        {crypto &&
                        crypto.market_data &&
                        crypto.market_data.market_cap &&
                        crypto.market_data.market_cap.usd
                          ? Number(
                              crypto.market_data.market_cap.usd
                            ).toLocaleString()
                          : "unknown"}
                      </Typography>
                    </Box>

                    <Stack
                      sx={{
                        mt: 2,
                      }}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Button
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.misc.success + 90,
                          "&:hover": {
                            backgroundColor: (theme) =>
                              theme.palette.misc.success,
                          },
                        }}
                        onClick={() => {
                          setDrawerType("buy");
                          toggleDrawer();
                        }}
                        fullWidth
                      >
                        <Typography sx={{ fontWeight: "500", color: "white" }}>
                          BUY
                        </Typography>
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.misc.error + 90,
                          "&:hover": {
                            backgroundColor: (theme) =>
                              theme.palette.misc.error,
                          },
                        }}
                        onClick={() => {
                          setDrawerType("sell");
                          toggleDrawer();
                        }}
                        fullWidth
                      >
                        <Typography sx={{ fontWeight: "500", color: "white" }}>
                          SELL
                        </Typography>
                      </Button>
                    </Stack>
                    <Typography sx={{ mt: 2 }}>Performance</Typography>
                    <Grid
                      container
                      spacing={1}
                      marginTop={0.2}
                      marginBottom={1}
                    >
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data
                                ?.price_change_percentage_1h_in_currency?.usd <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_1h_in_currency
                                  ?.usd < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data
                                ?.price_change_percentage_1h_in_currency?.usd
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">1H</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data?.price_change_percentage_7d <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_7d < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data?.price_change_percentage_7d
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">1W</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data?.price_change_percentage_14d <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_14d < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data?.price_change_percentage_14d
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">2W</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data?.price_change_percentage_30d <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_30d < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data?.price_change_percentage_30d
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">1M</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data?.price_change_percentage_60d <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_60d < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data?.price_change_percentage_60d
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">2M</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12 / 3}>
                        <Stack
                          sx={{
                            alignItems: "center",
                            borderRadius: 1,
                            flexDirection: "column",
                            p: 1,
                            backgroundColor: (theme) =>
                              crypto?.market_data?.price_change_percentage_1y <
                              0
                                ? theme.palette.misc.error + 30
                                : theme.palette.misc.success + 30,
                          }}
                        >
                          <Typography
                            variant="button"
                            sx={{
                              color: (theme) =>
                                crypto?.market_data
                                  ?.price_change_percentage_1y < 0
                                  ? theme.palette.misc.error
                                  : theme.palette.misc.success,
                            }}
                          >
                            {`${Number(
                              crypto?.market_data?.price_change_percentage_1y
                            ).toFixed(2)}%`}
                          </Typography>
                          <Typography variant="body2">1Y</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress thickness={8} size={30} color="secondary" />
            </Box>
          )}
        </>
      </Box>
    </>
  );
}

export default CryptosInfo;
